<template>
  <div id="nav">
    <router-link to="/">Home</router-link>
  </div>
  <router-view />
  <Settings />
</template>

<script>
import Settings from "@/components/Settings";

export default {
  components: {
    Settings,
  },
};
</script>

<style>
body {
  background: #383838;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #aaa;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #aaa;
}

#nav a.router-link-exact-active {
  color: #ccc;
}
</style>
