<template>
  <div class="home">
    <div class="w-3/4 md:w-1/2 mx-auto p-3">
      <div
        class="w-full flex justify-center items-center p-3"
        style="background: #323232"
      >
        <form class="w-3/4 p-3">
          <label class="font-bold" for="author">Autor</label>
          <input
            id="author"
            v-model="data.author"
            class="w-full p-2 rounded"
            type="text"
            style="background: #383838"
          />
          <label class="font-bold" for="message">Treść</label>
          <textarea
            id="message"
            v-model="data.message"
            class="w-full p-2 rounded"
            style="background: #383838"
          />
          <button class="bg-red-600 rounded p-2" @click.prevent="submit">
            Dodaj
          </button>
        </form>
      </div>
      <div class="w-full">
        <div v-if="loading" class="w-3/4 p-3 mx-auto">
          <img class="mx-auto" alt="Loading" src="../assets/loading.svg" />
        </div>
        <div
          class="w-full mx-auto p-3 mt-2 mb-2"
          v-else-if="!loading && posts.length > 0"
          v-for="post in posts"
          :key="post.id"
          style="background: #323232"
        >
          <div class="text-xs text-left p-2">
            {{ new Date(post.publishedAt).toLocaleString() }} |
            {{ post.author }}
          </div>
          <div class="text-md text-left p-3">{{ post.message }}</div>
        </div>
        <div
          class="w-full mx-auto p-3 mt-2 mb-2"
          v-if="!loading && posts.length === 0"
          style="background: #323232"
        >
          <div class="text-md text-center p-3">
            Nie ma nic do wyświetlenia 🙁
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      loading: true,
      data: {
        author: "",
        message: "",
      },
      posts: [],
    };
  },
  mounted() {
    this.getPosts();
  },
  methods: {
    getPosts() {
      axios
        .get(localStorage.getItem("backend_url") + "posts")
        .then((res) => {
          this.posts = res.data.posts;
        })
        .catch((err) => {
          console.error(`Unable to get posts`, err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      axios
        .post(localStorage.getItem("backend_url") + "posts", {
          author: this.data.author,
          message: this.data.message,
        })
        .then(() => {
          this.getPosts();
        })
        .catch((err) => {
          console.error(`Unable to add post`, err);
        });
    },
  },
};
</script>
