<template>
  <div v-if="isEnabled" class="settings-modal">
      <div class="settings-window">
          <form @submit.prevent="saveSettings">
            <label for="backend_url">Adres Backendu:</label>
            <input v-model="url" id="backend_url" type="text">
            <input type="submit" value="Zapisz">
          </form>
      </div>
  </div>
  <div class="settings" @click='toggleSettings'>
    <img v-if="!isEnabled" class="settings-icon" src="../assets/settings.svg">
    <img v-else class="settings-icon" src="../assets/close.svg">
  </div>
</template>

<script>
export default {
  name: "Settings",
  data () {
      return {
          url: '',
          isEnabled: false,
      }
  },
  created () {
    this.url = localStorage.getItem('backend_url')
  },
  methods: {
      toggleSettings () {
          this.isEnabled = !this.isEnabled;
      },
      saveSettings () {
          localStorage.setItem('backend_url', this.url);
          this.toggleSettings()
      }
  }
};
</script>

<style scoped>
    .settings-modal {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0,0,0,0.5);
    }
    .settings-window {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 80%;
        background: #fff;
        padding: 0.75rem;
    }
    .settings {
        position: fixed;
        display: flex;
        bottom: 1%;
        right: 1%;
        background: rgba(0,0,0,0.8);
        width: 48px;
        height: 48px;
        padding: 0.25em;
        border-radius: 9px;
    }
    .settings-icon {
        transition: background 0.25s ease-in;
    }
    .settings-icon:hover {
        transition: background 0.35s ease-out;
    }
    label {
        width: 100%;
        text-align: left;
        font-weight: bold;
    }
    input[type=text] {
        width: 80%;
        padding: 0.45em;
        border-radius: 6px;
        margin: 2px 0px 2px 0px;
        border: 1px solid #aaa;
        outline: none;
    }
    input[type=submit] {
        background: rgb(200,12,12);
        border: 1px solid rgb(200,12,12);
        color: white;
        font-weight: bold;
        font-size: 0.95em;
        padding: 0.85em;
        border-radius: 6px;
        margin: 2px 0px 2px 0px;
    }
    input[type=submit]:hover {
        transition-duration: 0.5s;
        background: rgb(150,12,12);
    }
</style>
